<template>
    <div>
        <div v-if="IsGettingQuotes">
            <loading-control :loading-message="'Loading'" />
        </div>

        <div class="holder">
            <div class="mainPolicyDiv" v-if="!IsGettingQuotes">
                <p>
                    <span>
                        <label class="numbers">3</label>
                        <label class="clientDetails">Policy detail</label>
                    </span>

                    <span style="float:right">
                        <label class="percentageCompleted">60% completed</label>
                    </span>
                </p>
                <p>
                    <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="60"></el-progress>
                </p>
                <div class="item">
                    <h1 class="Title">What are the policy details?</h1>
                </div>
                <el-form label-position="top" ref="ruleFormUserSelection" :model="UserSelection" :rules="rules">
                    <el-form-item v-if="Customers !== null && Customers.isLifeTwo" class="labels" label="Lives assured" prop="selectedCustomerType">
                        <el-select v-model="UserSelection.selectedCustomerType" placeholder="Please select">
                            <el-option :value="0" :label="Customers.life1FirstName + ' ' + Customers.life1LastName">{{Customers.life1FirstName + " " + Customers.life1LastName}}</el-option>
                            <el-option v-if="Customers.isLifeTwo" :value="1" :label="Customers.life2FirstName + ' ' + Customers.life2LastName">{{Customers.life2FirstName + " " + Customers.life2LastName}}</el-option>
                            <el-option :value="2" label="Both">Both</el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item v-if="userDetails.defaultIntegration === 'UNDERWRITEME'" class="labels" label="Cover basis" prop="coverBasis">
                        <el-select v-model="UserSelection.coverBasis" placeholder="Please select" style="width: 186px;">
                            <el-option label="Level" value="LEVEL" />
                            <el-option label="Decreasing" value="DECREASING" />
                            <el-option label="Increasing" value="INCREASING" />
                        </el-select>
                    </el-form-item>

                    <el-form-item v-else class="labels" label="Is this a decreasing policy?">
                        <el-select v-model="DecreasingPolicy" placeholder="Please select" style="width: 186px;">
                            <el-option label="Yes" :value="true"></el-option>
                            <el-option label="No" :value="false"></el-option>
                        </el-select>
                    </el-form-item>

                    <div v-if="userDetails.defaultIntegration === 'IPIPELINE'">
                        <el-form-item label="Policy Interest Rate" prop="policyInterestRate" v-if="DecreasingPolicy === 'Yes' || DecreasingPolicy === true" style="padding-bottom: 10px;">
                            <el-input type="number" :min="1.0" :max="20.0" :step="0.1" style="width: 186px;" v-model.number="UserSelection.policyInterestRate"></el-input>
                        </el-form-item>

                        <el-form-item v-else label="Indexation" class="labels" prop="termIndexation">
                            <el-select id="indexation" placeholder="Please select" v-model="UserSelection.termIndexation">
                                <el-option label="AWEI" value="AWEI"></el-option>
                                <el-option label="RPI" value="RPI"></el-option>
                                <el-option label="Level" value="Level"></el-option>
                                <el-option label="1%" value="One"></el-option>
                                <el-option label="2%" value="Two"></el-option>
                                <el-option label="3%" value="Three"></el-option>
                                <el-option label="4%" value="Four"></el-option>
                                <el-option label="5%" value="Five"></el-option>
                                <el-option label="6%" value="Six"></el-option>
                                <el-option label="7%" value="Seven"></el-option>
                                <el-option label="8%" value="Eight"></el-option>
                                <el-option label="9%" value="Nine"></el-option>
                                <el-option label="10%" value="Ten"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div v-if="userDetails.defaultIntegration === 'UNDERWRITEME'">
                        <el-form-item label="Decreasing rate" class="labels" v-if="userSelection.coverBasis === 'DECREASING'" prop="termIndexation">
                            <el-select id="indexation" placeholder="Please select" v-model="UserSelection.termIndexation">
                                <el-option label="5%" value="Five"></el-option>
                                <el-option label="6%" value="Six"></el-option>
                                <el-option label="7%" value="Seven"></el-option>
                                <el-option label="8%" value="Eight"></el-option>
                                <el-option label="9%" value="Nine"></el-option>
                                <el-option label="10%" value="Ten"></el-option>
                                <el-option label="11%" value="Eleven"></el-option>
                                <el-option label="12%" value="Twelve"></el-option>
                                <el-option label="13%" value="Thirteen"></el-option>
                                <el-option label="14%" value="Fourteen"></el-option>
                                <el-option label="15%" value="Fifteen"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Increasing rate" class="labels" v-if="userSelection.coverBasis === 'INCREASING'" prop="termIndexation">
                            <el-select id="indexation" placeholder="Please select" v-model="UserSelection.termIndexation">
                                <el-option label="RPI" value="RPI"></el-option>
                                <el-option label="1%" value="One"></el-option>
                                <el-option label="2%" value="Two"></el-option>
                                <el-option label="3%" value="Three"></el-option>
                                <el-option label="4%" value="Four"></el-option>
                                <el-option label="5%" value="Five"></el-option>
                                <el-option label="6%" value="Six"></el-option>
                                <el-option label="7%" value="Seven"></el-option>
                                <el-option label="8%" value="Eight"></el-option>
                                <el-option label="9%" value="Nine"></el-option>
                                <el-option label="10%" value="Ten"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div style="display: inline-flex;">
                        <el-form-item label="Term length(years)" prop="policyTerm">
                            <el-input :min="5" step="1" :integer="true" style="width: 186px;" units="years" v-model.number="UserSelection.policyTerm"></el-input>
                        </el-form-item>

                        <el-form-item v-if="!DecreasingPolicy && !(PolicyType !== '' && PolicyType === 'Joint')" label="To age" class="labels" style="padding-left:10px;" prop="toAge">
                            <el-input :min="21" step="1" :integer="true" :max="70" style=" width: 166px;" type="age" v-model.number="UserSelection.toAge"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="Life cover amount" class="labels" prop="termBenefitAmount">
                        <el-input v-model.number="UserSelection.termBenefitAmount" type="number" min="5" max="99999.99" id="termBenefitAmount"></el-input>
                    </el-form-item>
                    <el-form-item label="Critical illness cover amount" class="labels" prop="termCriticalIllnessCoverAmount">
                        <el-input v-model.number="UserSelection.termCriticalIllnessCoverAmount" type="number" min="5" max="99999.99" id="termCriticalIllnessCoverAmount"></el-input>
                    </el-form-item>
                    <el-form-item label="Life or earlier CI cover amount" class="labels" prop="termLifeOrEarlierCriticalIllnessCoverAmount">
                        <el-input v-model.number="UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount" type="number" min="5" max="99999.99" id="termLifeOrEarlierCriticalIllnessCoverAmount"></el-input>
                    </el-form-item>
                    <el-form-item label="Waiver of premium" class="labels" prop="waiverOfPremium">
                        <el-select id="WaiverOfPremium" placeholder="Please select" v-model="UserSelection.waiverOfPremium">
                            <el-option label="None" value="None"></el-option>
                            <el-option label="First Life" value="FirstLife"></el-option>
                            <el-option label="Second Life" value="SecondLife" v-if="UserSelection.selectedCustomerType == 2"></el-option>
                            <el-option label="Both Lives" value="BothLives" v-if="UserSelection.selectedCustomerType == 2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Total permanent disability" prop="termTotalPermanentDisability" v-if="UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null && UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount > 0 || UserSelection.termCriticalIllnessCoverAmount !== null && UserSelection.termCriticalIllnessCoverAmount > 0">
                        <el-select placeholder="Please select" v-model="UserSelection.termTotalPermanentDisability">
                            <el-option label="Yes" :value="true"></el-option>
                            <el-option label="No" :value="false"></el-option>
                        </el-select>
                    </el-form-item>

                    <div v-for="(child,childindex) in UserSelection.children" :key="childindex">
                        <div style="margin-bottom:10px;">
                            <label style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px;color: #0F172A;"> Child {{ childindex + 1 }} </label>
                            <el-link type="primary" @click="RemoveChild(child)" style="float:right;font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px; text-decoration-line: underline; color: #0F172A;"> Remove </el-link>
                        </div>

                        <el-form-item for="'Child + childindex + 1 + 'DOB'" class="labels" label="Date of birth">
                            <!--Child {{ childindex + 1 }} DOB-->
                            <age-control v-model="child.dateOfBirth" />                        
                        </el-form-item>

                        <el-form-item for="'Child'+ childindex + 1 + 'Gender'" label="Child gender">
                            <!--Child {{ childindex + 1 }} Gender-->
                            <el-select placeholder="Please select" v-bind:id="'Child' + childindex + 1 + 'Gender'" v-model="child.gender">
                                <el-option label="Male" value="Male"></el-option>
                                <el-option label="Female" value="Female"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div>
                        <el-link type="primary" @click="AddChild()" style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px; text-decoration-line: underline; color: #0F172A;"> {{ UserSelection.children.length == 0 ? 'Add child' :  'Add another child' }} </el-link>
                    </div>

                    <div v-if="UserSelection.children.length == 0 && false">
                        No children added
                    </div>

                    <div class="btnNextPosition">
                        <el-button type="default" v-on:click.stop="$emit('back')"> <span class="el-icon-arrow-left"></span>Back</el-button>
                        <el-button type="success" @click.stop="GetQuotes('ruleFormUserSelection')">Next</el-button>
                    </div>
                </el-form>
            </div>
            <div class="summary" v-if="Customers != null">
                <label class="summaryTitle">Summary</label> <br />

                <div style="display:inline-flex">
                    <label class="summaryLabels">Client details</label>
                    <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels" style="margin-left: 120px; text-decoration: underline">Edit</el-link>
                </div>

                <div v-if="UserSelection.primaryClient !== null">
                    <label class="summaryDetail">{{UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname}}</label>
                    <label class="summaryDetail">
                        {{helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.primaryClient.classification)}}
                    </label>
                </div>

                <div v-if="UserSelection.secondaryClient !== null">
                    <label class="summaryDetail">{{UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname}}</label>
                    <label class="summaryDetail">
                        {{helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.secondaryClient.classification)}}
                    </label>
                </div>

                <hr style="border: 1px solid #A0A0A0;" />
                <label class="summaryLabels">Policy type</label>

                <label class="summaryDetail">{{ UserSelection.tool.toolName }}</label>
                <hr style="border: 1px solid #A0A0A0;" />

                <label class="summaryLabels">Policy details</label>
                <hr style="border: 1px solid #A0A0A0;" />

                <label class="summaryLabels">Quality of plans</label>
                <hr style="border: 1px solid #A0A0A0;" />

                <label class="summaryLabels">Additional info</label>
            </div>
        </div>

        <div v-if="false && !IsGettingQuotes">
            <fieldset class="mainfieldSet mainfieldSet990">
                <legend>Policy Details</legend>
                <p>
                    <label for="decreasingPolicy">Is this a decreasing policy?</label>
                    <select v-model="DecreasingPolicy">
                        <option :value="true">
                            yes
                        </option>
                        <option :value="false">
                            no
                        </option>
                    </select>
                </p>
                <number-input min="5" step="1" :integer="true" label="Term" units="years" v-model="UserSelection.policyTerm" />
                <span>
                    <number-input v-if="!DecreasingPolicy" min="21" step="1" max="70" :integer="true" label="To age" v-model="UserSelection.toAge" />
                </span>
                <p>
                    <label for="termBenefitAmount">Life cover amount</label>
                    <span class="AmountAssured">
                        <input v-model.number="UserSelection.termBenefitAmount" type="number" min="5" max="99999.99" id="termBenefitAmount" />
                    </span>
                </p>
                <p>
                    <label for="termCriticalIllnessCoverAmount">Critical illness cover amount</label>
                    <span class="AmountAssured">
                        <input v-model.number="UserSelection.termCriticalIllnessCoverAmount" type="number" min="5" max="99999.99" id="termBenefitAmount" />
                    </span>
                </p>
                <p>
                    <label for="termLifeOrEarlierCriticalIllnessCoverAmount">Life or earlier CI cover amount</label>
                    <span class="AmountAssured">
                        <input v-model.number="UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount" type="number" min="5" max="99999.99" id="termLifeOrEarlierCriticalIllnessCoverAmount" />
                    </span>
                </p>
                <span v-if="false">
                    <number-input v-if="DecreasingPolicy" min="0" max="20" step="1" :integer="true" label="Policy interest rate" units="%" v-model="UserSelection.mortgageInterestRate" />
                </span>
                <span>
                    <p v-if="!DecreasingPolicy">
                        <label for="indexation">Indexation</label>
                        <select id="indexation" v-model="UserSelection.termIndexation">
                            <option value="AWEI">AWEI</option>
                            <option value="RPI">RPI</option>
                            <option value="Level">Level</option>
                            <option value="One">1%</option>
                            <option value="Two">2%</option>
                            <option value="Three">3%</option>
                            <option value="Four">4%</option>
                            <option value="Five">5%</option>
                            <option value="Six">6%</option>
                            <option value="Seven">7%</option>
                            <option value="Eight">8%</option>
                            <option value="Nine">9%</option>
                            <option value="Ten">10%</option>
                        </select>
                    </p>
                </span>
                <p v-if="UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null && UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount > 0 || UserSelection.termCriticalIllnessCoverAmount !== null && UserSelection.termCriticalIllnessCoverAmount > 0">
                    <label for="indexation">Total permanent disability</label>
                    <select v-model="UserSelection.termTotalPermanentDisability">
                        <option :value="true">
                            yes
                        </option>
                        <option :value="false">
                            no
                        </option>
                    </select>
                </p>
            </fieldset>
            <fieldset class="mainfieldSet mainfieldSet990">
                <legend>Client Details</legend>
                <p>
                    <label for="userCustomer">Select Customer </label>
                    <select v-if="Customers !== null" id="userCustomer" v-model="UserSelection.selectedCustomerType">
                        <option :value="0">{{Customers.life1FirstName + " " + Customers.life1LastName}}</option>
                        <option :value="1" v-if="Customers.isLifeTwo">{{Customers.life2FirstName + " " + Customers.life2LastName}}</option>
                        <option :value="2">Both</option>
                    </select>
                </p>
                <p v-if="false">
                    <label for="primaryTitle">First person title</label>
                    <!--<input id="primaryTitle" v-model="UserSelection.primaryClient.title" />-->
                    <label>{{UserSelection.primaryClient.title}}</label>
                </p>
                <p>
                    <label for="primaryForename">First person forename(s)</label>
                    <!--<input id="primaryForename" v-model="UserSelection.primaryClient.forename" />-->
                    <label>{{UserSelection.primaryClient.forename}}</label>
                </p>
                <p>
                    <label for="primarySurname">First person surname</label>
                    <!--<input id="primarySurname" v-model="UserSelection.primaryClient.surname" />-->
                    <label>{{UserSelection.primaryClient.surname}}</label>
                </p>
                <p>
                    <label for="PrimaryClientDOB">First person DOB:</label>
                    <!--<date-control id="PrimaryClientDOB" minage="16" maxage="90" v-model="UserSelection.primaryClient.dateOfBirth" />-->
                    <label>{{UserSelection.primaryClient.dateOfBirth}}</label>
                </p>
                <p>
                    <label for="PrimaryClientGender">First person gender: </label>
                    <!--<select v-model="PrimaryClientGender" id="PrimaryClientGender"><option value="Male">Male</option><option value="Female">Female</option></select>-->
                    <label>{{PrimaryClientGender}}</label>
                </p>
                <p>
                    <label for="PrimaryClientSmokerStatus">First person smoking status: </label>
                    <label id="PrimaryClientSmokerStatus">
                        {{ PrimaryClientSmokerStatus === '_NonSmoker' ? 'Non-Smoker' : PrimaryClientSmokerStatus === '_Former_Smoker' ? 'Ex-regular smoker' : PrimaryClientSmokerStatus === '_Smoker' ? 'Smoker' : ''  }}
                        <img v-if="PrimaryClientSmokerStatus !== ''" src="https://cdn.advisersoftware.com/images/i.svg" v-on:click="showingSmokerDefinitions = true" class="ibutton" />
                    </label>

                    <!--<select v-model="PrimaryClientSmokerStatus" id="PrimaryClientSmokerStatus">
                        <option value="_NonSmoker">Non-Smoker</option>
                        <option value="_Former_Smoker">Ex-regular smoker</option>
                        <option value="_Smoker">Smoker</option>
                    </select>-->
                </p>
                <p v-if="false">
                    <label for="PrimaryClientFractureCover">First person fracture cover </label>
                    <label id="PrimaryClientFractureCover">{{ UserSelection.primaryClient.termFractureCover === 'True' ? 'Yes' : 'No' }}</label>
                    <!--<select id="PrimaryClientFractureCover" v-model="UserSelection.primaryClient.termFractureCover">
                        <option :value="true">
                            yes
                        </option>
                        <option :value="false">
                            no
                        </option>
                    </select>-->
                </p>
                <p v-if="UserSelection.termTotalPermanentDisability">
                    <label for="primaryEmploymentStatus">First person employment status</label>
                    <select v-model="UserSelection.primaryClient.personEmployedStatus" id="primaryEmploymentStatus">
                        <option value="Employed">Employed</option>
                        <option value="SelfEmployed">Self-employed</option>
                        <option value="HousePerson">House person</option>
                    </select>
                </p>
                <Occupations mode="display" v-model="UserSelection.primaryClient.occupation" v-if="UserSelection.termTotalPermanentDisability && (UserSelection.primaryClient.personEmployedStatus === 'Employed' ||UserSelection.primaryClient.personEmployedStatus === 'SelfEmployed')" />
                <p>
                    <label for="PolicyType">Policy type: </label>
                    <label>{{PolicyType}}</label>
                    <!--<select v-model="PolicyType" id="PolicyType">
                        <option value="Single">Single</option>
                        <option value="Joint">Joint</option>
                    </select>-->
                </p>
                <p v-if="PolicyType === 'Joint' && false">
                    <label for="secondaryTitle">Second person title</label>
                    <!--<input id="secondaryTitle" v-model="UserSelection.secondaryClient.title" />-->
                    <label>{{UserSelection.secondaryClient.title}}</label>
                </p>
                <p v-if="PolicyType === 'Joint'">
                    <label for="secondaryForename">Second person forename(s)</label>
                    <!--<input id="secondaryForename" v-model="UserSelection.secondaryClient.forename" />-->
                    <label>{{UserSelection.secondaryClient.forename}}</label>
                </p>
                <p v-if="PolicyType === 'Joint'">
                    <label for="secondarySurname">Second person surname</label>
                    <!--<input id="secondarySurname" v-model="UserSelection.secondaryClient.surname" />-->
                    <label>{{UserSelection.secondaryClient.surname}}</label>
                </p>
                <p v-if="PolicyType === 'Joint'">
                    <label for="secondaryClientDOB">Second person DOB:</label>
                    <!--<date-control id="secondaryClientDOB" minage="16" maxage="90" v-model="UserSelection.secondaryClient.dateOfBirth" />-->
                    <label>{{UserSelection.secondaryClient.dateOfBirth}}</label>
                </p>
                <p v-if="PolicyType === 'Joint'">
                    <label for="secondaryClientGender">Second person gender: </label>
                    <!--<select v-model="SecondaryClientGender" id="secondaryClientGender"><option value="Male">Male</option><option value="Female">Female</option></select>-->
                    <label>{{SecondaryClientGender}}</label>
                </p>
                <p v-if="PolicyType === 'Joint'">
                    <label for="SecondaryClientSmokerStatus">Second person smoking status: </label>
                    <label>
                        {{ SecondaryClientSmokerStatus === '_NonSmoker' ? 'Non-Smoker' : SecondaryClientSmokerStatus === '_Former_Smoker' ? 'Ex-regular smoker' : SecondaryClientSmokerStatus === '_Smoker' ? 'Smoker' : ''  }}
                        <img v-if="SecondaryClientSmokerStatus !== ''" src="https://cdn.advisersoftware.com/images/i.svg" v-on:click="showingSmokerDefinitions = true" class="ibutton" />
                    </label>
                    <!--<select v-model="SecondaryClientSmokerStatus" id="SecondaryClientSmokerStatus">
                        <option value="Non-Smoker">Non-Smoker</option>
                        <option value="Ex-regular smoker">Ex-regular smoker</option>
                        <option value="Smoker">Smoker</option>
                    </select>-->
                </p>
                <p v-if="PolicyType === 'Joint' && false">
                    <label for="SecondaryClientFractureCover"> Second person fracture cover </label>
                    <label id="SecondaryClientFractureCover">{{ UserSelection.secondaryClient.termFractureCover === 'True' ? 'Yes' : 'No' }}</label>
                    <!--<select disabled class="ddlLabel" id="SecondaryClientFractureCover" v-model="UserSelection.secondaryClient.termFractureCover">
                        <option :value="true">
                            yes
                        </option>
                        <option :value="false">
                            no
                        </option>
                    </select>-->
                </p>
                <p v-if="PolicyType === 'Joint' && UserSelection.termTotalPermanentDisability">
                    <label for="secondaryEmploymentStatus">Second person employment status</label>
                    <select v-model="UserSelection.secondaryClient.personEmployedStatus" id="secondaryEmploymentStatus">
                        <option value="Employed">Employed</option>
                        <option value="SelfEmployed">Self-Employed</option>
                        <option value="HousePerson">House Person</option>
                    </select>
                </p>
                <Occupations mode="display" v-model="UserSelection.secondaryClient.occupation" v-if="(PolicyType === 'Joint') && UserSelection.termTotalPermanentDisability && (UserSelection.secondaryClient.personEmployedStatus === 'Employed' ||UserSelection.secondaryClient.personEmployedStatus === 'SelfEmployed')" />
                <p>
                    <label>Children:</label>
                </p>
                <table>
                    <tbody v-for="(child,childindex) in UserSelection.children" :key="childindex">
                        <tr>
                            <td>
                                <label for="'Child' + childindex + 1  + 'DOB'">Child {{childindex + 1 }} Age</label>
                            </td>
                            <td>
                                <age-control v-model="child.dateOfBirth" />
                                <br />
                                <date-control v-bind:id="'Child' + childindex + 1 + 'DOB'" minage="-5" maxage="23" v-model="child.dateOfBirth" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="'Child'+ childindex + 1 + 'Gender'">Child {{childindex + 1 }} Gender</label>
                            </td>
                            <td>
                                <select v-bind:id="'Child' + childindex + 1 + 'Gender'" v-model="child.gender">
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td class="navigation" colspan="2">
                                <button v-on:click.stop="RemoveChild(child)">Remove</button>
                            </td>
                        </tr>
                    </tbody>
                    <tr v-if="UserSelection.children.length == 0">
                        <td colspan="2">
                            No children added
                        </td>
                    </tr>
                </table>
                <button v-on:click.stop="AddChild()" class="buttonLeftMargin50"> {{ UserSelection.children.length == 0 ? 'Add child' :  'Add another child' }}</button> <br />

                <div v-if="showingSmokerDefinitions" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 10001; background-color: rgba(0, 0, 0, 0.5); text-align: center; padding-top: 250px;">
                    <div style="width: 100%; max-width: 600px; background-color: white; left: auto; right: auto; display: inline-block; text-align: left;">
                        <button v-on:click="showingSmokerDefinitions = false">Close</button>
                        <fieldset style="border: none;">
                            <legend style="color: black; border: none; text-align: center; padding-bottom: 20px;">Smoker Status</legend>
                            <ul>
                                <li style="list-style: none;">Current smoker - Any adult who smokes currently on a regular basis.</li>
                                <li style="list-style: none;">Ex-regular smokers - Any adult that has smoked regularly in the past, but not currently.</li>
                                <li style="list-style: none;">Non-smokers - Any adult that has never smoked on a regular basis. </li>
                            </ul>
                        </fieldset>
                    </div>
                </div>
            </fieldset>
            <fieldset class="navigation navigationCenterButton analysisbottomMargin50">
                <button v-on:click.stop="$emit('back')">Back</button>
                <button @click.stop="GetQuotes()">Next</button>
                <!--<button v-on:click.stop="$emit('policy-details-added')" :disabled="!CanProceed()">Next</button>-->
            </fieldset>
        </div>
    </div>
</template>
<style type="text/css" scoped>
    .Title {
        width: 366px;
        height: 20px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .TitleInputs {
        width: 166px;
        height: 48px;
        /*left: 283px;
            top: 229px;*/
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .el-input__inner {
        color: black;
    }

    .el-form--label-top .el-form-item__label {
        float: none;
        display: inline-block;
        text-align: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        color: #0F172A;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        /*letter-spacing: -0.04em;*/
        width: 194px;
        height: 40px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        width: 232px;
        height: 572px;
        top: 0;
        right: 150px;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .el-button {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 10px !important;
        position: static !important;
        width: 167px !important;
        height: 44px !important;
        left: 199px !important;
        top: 0px !important;
        border-radius: 8px !important;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #059669 !important;
        /*border-color: #059669 !important;*/
    }

        .el-button--success:focus, .el-button--success:hover {
            background: #059669 !important;
            border-radius: 8px !important;
            /*border-color: #059669 !important;*/
        }

        .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
            background: #E7EAEE !important;
            border-radius: 8px !important;
            /*border-color: #E7EAEE !important;*/
            font-style: normal !important;
            color: #A0ABBB !important;
        }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*    .el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    .labels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px;
        color: #0F172A;
    }

    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .clientDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .percentageCompleted {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.04em;
    }

    .holder {
        max-width: 1144px;
        min-width: 1044px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        position: relative;
    }

    .mainPolicyDiv {
        padding: 82px;
        position: absolute;
        width: 531px;
        left: 0;
        top: 0;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }
    /*.noBorder {
        border: none;
    }

    .ddlLabel {
        width: 115%*/ /* This hides the arrow icon */ /*;
        background-color: transparent*/ /* This hides the background */ /*;
        background-image: none;
        -webkit-appearance: none*/ /* Webkit Fix */ /*;
        border: none;
        box-shadow: none;
        padding: 0.3em 0.5em;
    }*/
</style>
<script>
    import $ from 'jquery';
    import DateControl from "./DateControl.vue";
    import Occupations from "./Occupations.vue";
    import NumberInput from './NumberInput.vue';
    import LoadingControl from './LoadingControl.vue';
    import AgeControl from './AgeControl.vue';
    import helper from './HelperMethods.vue';
    export default
        {
            components: { DateControl, Occupations, NumberInput, LoadingControl, AgeControl },
            name: 'PolicyDetails',
            props: ["userSelection", "userDetails"],
            watch: {
                'UserSelection.policyTerm': function (newValue) {
                    if (newValue !== null)
                        this.UserSelection.toAge = null;
                },
                'UserSelection.toAge': function (newValue) {
                    if (newValue !== null)
                        this.UserSelection.policyTerm = null;
                },
                'UserSelection.termBenefitAmount': function (newValue) {
                    if (newValue !== null) {
                        if (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null)
                            this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;

                        if (newValue == 0)
                            this.UserSelection.termBenefitAmount = null;
                    }
                    this.CheckTool();
                },
                'UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount': function (newValue) {
                    if (newValue !== null) {
                        if (this.UserSelection.termBenefitAmount !== null)
                            this.UserSelection.termBenefitAmount = null;
                        if (this.UserSelection.termCriticalIllnessCoverAmount !== null)
                            this.UserSelection.termCriticalIllnessCoverAmount = null;

                        if (newValue == 0)
                            this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;
                    }
                    this.CheckTool();
                },
                'UserSelection.termCriticalIllnessCoverAmount': function (newValue) {
                    if (newValue !== null) {
                        if (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null)
                            this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;

                        if (newValue == 0)
                            this.UserSelection.termCriticalIllnessCoverAmount = null;
                    }
                    this.CheckTool();
                },
                'UserSelection.selectedCustomerType': function () {

                    this.PolicyType = "Single";

                    if (this.UserSelection.selectedCustomerType === 0 && this.Customers !== null && this.Customers !== undefined) {
                        this.PrimaryClientGender = this.Customers.life1Gender;
                        this.PrimaryClientSmokerStatus = this.Customers.life1SmokingStatus;

                        this.UserSelection.primaryClient.dateOfBirth = this.Customers.life1DateOfBirth;
                        this.UserSelection.primaryClient.forename = this.Customers.life1FirstName;
                        this.UserSelection.primaryClient.personEmployedStatus = "Employed";
                        this.UserSelection.primaryClient.salary = this.Customers.life1Salary;
                        this.UserSelection.primaryClient.surname = this.Customers.life1LastName;
                        this.UserSelection.primaryClient.title = this.Customers.life1Title;
                        this.UserSelection.primaryClient.occupation = this.Customers.life1Occupation;
                    }
                    else if (this.UserSelection.selectedCustomerType === 1 && this.Customers !== null && this.Customers !== undefined) {

                        this.PrimaryClientGender = this.Customers.life2Gender;
                        this.PrimaryClientSmokerStatus = this.Customers.life2SmokingStatus;

                        this.UserSelection.primaryClient.dateOfBirth = this.Customers.life2DateOfBirth;
                        this.UserSelection.primaryClient.forename = this.Customers.life2FirstName;
                        this.UserSelection.primaryClient.personEmployedStatus = "Employed";
                        this.UserSelection.primaryClient.salary = this.Customers.life2Salary;
                        this.UserSelection.primaryClient.surname = this.Customers.life2LastName;
                        this.UserSelection.primaryClient.title = this.Customers.life2Title;
                        this.UserSelection.primaryClient.occupation = this.Customers.life2Occupation;
                    }
                    else if (this.UserSelection.selectedCustomerType === 2 && this.Customers !== null && this.Customers !== undefined) {
                        this.PrimaryClientGender = this.Customers.life1Gender;
                        this.PrimaryClientSmokerStatus = this.Customers.life1SmokingStatus;

                        this.UserSelection.primaryClient.dateOfBirth = this.Customers.life1DateOfBirth;
                        this.UserSelection.primaryClient.forename = this.Customers.life1FirstName;
                        this.UserSelection.primaryClient.personEmployedStatus = "Employed";
                        this.UserSelection.primaryClient.salary = this.Customers.life1Salary;
                        this.UserSelection.primaryClient.surname = this.Customers.life1LastName;
                        this.UserSelection.primaryClient.title = this.Customers.life1Title;
                        this.UserSelection.primaryClient.occupation = this.Customers.life1Occupation;

                        if (this.Customers.isLifeTwo) {
                            this.PolicyType = "Joint";
                        }

                        this.SecondaryClientGender = this.Customers.life2Gender;
                        this.SecondaryClientSmokerStatus = this.Customers.life2SmokingStatus;

                        this.UserSelection.secondaryClient.dateOfBirth = this.Customers.life2DateOfBirth;
                        this.UserSelection.secondaryClient.forename = this.Customers.life2FirstName;
                        this.UserSelection.secondaryClient.personEmployedStatus = "Employed";
                        this.UserSelection.secondaryClient.salary = this.Customers.life2Salary;
                        this.UserSelection.secondaryClient.surname = this.Customers.life2LastName;
                        this.UserSelection.secondaryClient.title = this.Customers.life2Title;
                        this.UserSelection.secondaryClient.occupation = this.Customers.life2Occupation;
                    }
                    this.UserSelection.waiverOfPremium = "None";
                },
                'UserSelection.coverBasis': function (newValue) {
                    this.UserSelection.termIndexation = "";
                    this.DecreasingPolicy = newValue === "DECREASING";
                    this.CheckTool();
                },
                'DecreasingPolicy': function (newValue) {
                    if (newValue == false) {
                        this.UserSelection.policyInterestRate = 0;
                    }
                }
            },
            data: function () {
                var thisControl = this;
                return {
                    helper: helper,
                    IsGettingQuotes: false,
                    showingSmokerDefinitions: false,
                    Customers: [],
                    ruleFormUserSelection: {
                        selectedCustomerType: '',
                        policyTerm: null,
                        toAge: null,
                        termTotalPermanentDisability: '',
                        termIndexation: '',
                        termLifeOrEarlierCriticalIllnessCoverAmount: '',
                        termCriticalIllnessCoverAmount: '',
                        termBenefitAmount: '',
                        waiverOfPremium: '',
                        coverBasis: '',
                        policyInterestRate: null,
                    },
                    rules: {
                        selectedCustomerType: [
                            { required: true, message: 'Please select customer type', trigger: 'change' }
                        ],
                        policyTerm: [
                            {
                                validator: function (rule, value, callback) {
                                    if (thisControl.UserSelection.policyTerm === null || thisControl.UserSelection.policyTerm === "")
                                        if (thisControl.UserSelection.toAge === null || thisControl.UserSelection.toAge === "") {
                                            callback(new Error("Required if to age is not supplied."))

                                        }
                                    if (value !== null && value !== "" && typeof (value) !== "number") {
                                        callback(new Error("Term length must be a number."))
                                    }
                                    callback();//all good here
                                },
                                trigger: 'change'
                            }
                        ],
                        toAge: [
                            {
                                validator: function (rule, value, callback) {
                                    if (thisControl.UserSelection.policyTerm === null || thisControl.UserSelection.policyTerm === "")
                                        if (thisControl.UserSelection.toAge === null || thisControl.UserSelection.toAge === "") {
                                            callback(new Error("Required if term length is not supplied."))

                                        }
                                    if (value !== null && value !== "" && typeof (value) !== "number") {
                                        callback(new Error("To age must be a number."))
                                    }
                                    callback();//all good here
                                },
                                trigger: 'change'
                            }
                        ],
                        termTotalPermanentDisability: [
                            { required: true, message: 'Please select Total permanent disability', trigger: 'change' },
                        ],
                        termBenefitAmount: [
                            {
                                validator: function (rule, value, callback) {
                                    if (thisControl.UserSelection.termBenefitAmount === null || thisControl.UserSelection.termBenefitAmount === "")
                                        if (thisControl.UserSelection.termCriticalIllnessCoverAmount === null || thisControl.UserSelection.termCriticalIllnessCoverAmount === "") {
                                            if (thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === "") {
                                                return callback(new Error("Required if 'Critical illness amount' or 'Life or earlier CI cover amount' is not supplied."))
                                            }
                                        }
                                    if (value !== null && value !== "" && !Number.isInteger(value)) {
                                        return callback(new Error("life cover amount must be a whole number."))
                                    }
                                    callback();//all good here
                                },
                                trigger: 'change'
                            }
                        ],
                        termCriticalIllnessCoverAmount: [
                            {
                                validator: function (rule, value, callback) {
                                    if (thisControl.UserSelection.termCriticalIllnessCoverAmount === null || thisControl.UserSelection.termCriticalIllnessCoverAmount === "")
                                        if ((thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === "")
                                            && (thisControl.UserSelection.termTotalPermanentDisability === null || thisControl.UserSelection.termTotalPermanentDisability === "")) {
                                            return callback(new Error("Required if Life or earlier CI cover amount is not supplied and select Total permanent disability."))
                                        }
                                    if (value !== null && value !== "" && !Number.isInteger(value)) {
                                        return callback(new Error("Critical illness cover amount must be a whole number."))
                                    }
                                    callback();//all good here
                                },
                                trigger: 'change'
                            }
                        ],
                        termLifeOrEarlierCriticalIllnessCoverAmount: [
                            {
                                validator: function (rule, value, callback) {
                                    if (thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === "")
                                        if ((thisControl.UserSelection.termBenefitAmount === null || thisControl.UserSelection.termBenefitAmount === "")
                                            && (thisControl.UserSelection.termCriticalIllnessCoverAmount === null || thisControl.UserSelection.termCriticalIllnessCoverAmount === "")
                                            && (thisControl.UserSelection.termTotalPermanentDisability === null || thisControl.UserSelection.termTotalPermanentDisability === "")) {
                                            return callback(new Error("Required if Life cover amount & Critical illness cover amount is not supplied and select Total permanent disability"))
                                        }
                                    if (value !== null && value !== "" && !Number.isInteger(value)) {
                                        return callback(new Error(" Life or earlier CI cover amount must be a whole number."))
                                    }
                                    callback();//all good here
                                },
                                trigger: 'change'
                            }
                        ],
                        termIndexation: [
                            { required: true, message: 'Please select Indexation', trigger: 'change' }
                        ],
                        policyInterestRate: [
                            {
                                required: true,
                                validator: function (rule, value, callback) {
                                    const minValue = 1.0;
                                    const maxValue = 20.0;

                                    if (thisControl.DecreasingPolicy === 'Yes' || thisControl.DecreasingPolicy === true) {
                                        if (value === null || value === 0) {
                                            callback(new Error("Required if decreasing policy is Yes"));
                                        } else if (value >= minValue && value <= maxValue && (Number.isInteger(value) || value.toFixed(1) == value)) {
                                            callback();
                                        } else {
                                            callback(new Error("Please enter a number with 1 decimal place between 1.0 and 20.0"));
                                        }
                                    }

                                    if (value !== null && value !== "" && typeof (value) !== "number") {
                                        callback(new Error("Policy interest rate must be a number."));
                                    }
                                    callback(); // All good here
                                },
                                trigger: 'change'
                            }
                        ],
                    },
                }
            },
            created: function () {
                var thisControl = this;

                thisControl.UserSelection.ipCommissionType = thisControl.UserDetails.commissionType;
                thisControl.UserSelection.ipIndemnityRequired = thisControl.UserDetails.indemnityRequired;
                thisControl.UserSelection.ipCommissionPercentageRequired = thisControl.UserDetails.commissionPercentageRequired;

                if (thisControl.UserSelection.primaryClient === null) {
                    this.UserSelection.primaryClient = {
                        classification: "Male_NonSmoker", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed', termFractureCover: false
                    };
                }
                if (this.UserSelection.selectedCustomerType == null) {
                    thisControl.UserSelection.primaryClient = { classification: "", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed', termFractureCover: false };
                    thisControl.PrimaryClientGender = '';
                    thisControl.PolicyType = '';
                }
                else
                    thisControl.UserSelection.selectedCustomerType = parseInt(thisControl.UserSelection.selectedCustomerType);

                thisControl.GetCustomer();

                if (thisControl.UserSelection.children === null) {
                    this.UserSelection.children = [];
                    this.UserSelection.childNames = [];
                }
            },
            mounted: function () {
                this.UserSelection.expressTemplate = null;
                this.UserSelection.pgpLinkedAnalysisReference  = null;
                this.UserSelection.umeLinkedAnalysisReference  = null;
            },
            methods: {
                GetQuotes: function (formName) {
                    var thisControl = this;
                    this.$refs[formName].validate(function (valid) {
                        if (valid) {
                            thisControl.CheckTool();
                            thisControl.$emit('policy-details-added');
                        } else {
                            alert("Please correct errors before continuing.")
                        }
                    });
                },
                CanProceed: function () {
                    return this.UserSelection.policyTerm > 0 && this.UserSelection.amountAssured > 0;
                },
                CheckTool: function () {
                    var hasCI = this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null || this.UserSelection.termCriticalIllnessCoverAmount;
                    var isMP = this.DecreasingPolicy;
                    let selectedTool = this.UserSelection.tool.toolReference;

                    if (hasCI && isMP) {
                        selectedTool = 'MPCIPro - Pricing';
                    } else if (hasCI && !isMP) {
                        selectedTool = 'CIPlus - Pricing';
                    } else if (!hasCI && isMP) {
                        selectedTool = 'MPPro - Pricing';
                    } else if (!hasCI && !isMP) {
                        selectedTool = 'LifePro - Pricing';
                    }

                    if (
                        (this.UserSelection.termCriticalIllnessCoverAmount === null || this.UserSelection.termCriticalIllnessCoverAmount <= 0)
                        && (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount <= 0)
                    ) {
                        this.UserSelection.termTotalPermanentDisability = false;
                    }

                    this.$emit("tool-updated", selectedTool);
                },
                GetSmokerToolTip: function () {
                    var tooTips =
                        ["Current smoker - Any adult who smokes currently on a regular basis.",
                            "Ex-regular smokers - Any adult that has smoked regularly in the past, but not currently.",
                            "Non-smokers - Any adult that has never smoked on a regular basis."];

                    return tooTips.reduce(function (sofar, tooTip) { return sofar + "\n" + tooTip; }, "").trim();
                },
                AddChild: function () {
                    this.UserSelection.children.push({
                        gender: "Male",
                        dateOfBirth: '2015-01-01'
                    });
                    this.UserSelection.childNames.push("");
                },
                RemoveChild: function (child) {
                    this.UserSelection.childNames.splice(this.UserSelection.children.indexOf(child), 1);
                    this.UserSelection.children.splice(this.UserSelection.children.indexOf(child), 1);
                },
                GetCustomer: function () {
                    var thisControl = this;
                    this.Customers = null;

                    $.ajax({
                        type: 'GET',
                        url: '/Customer/' + thisControl.UserSelection.customerReference,
                        data: {},
                        success: function (customer) {
                            if (customer.customerReference === null)
                                customer.customerReference = thisControl.UserSelection.customerReference;

                            thisControl.Customers = customer;

                            if (thisControl.Customers.life1DateOfBirth !== null)
                                thisControl.Customers.life1DateOfBirth = customer.life1DateOfBirth.slice(0, 10);

                            if (thisControl.Customers.life2DateOfBirth !== null)
                                thisControl.Customers.life2DateOfBirth = customer.life2DateOfBirth.slice(0, 10);

                            if (!customer.isLifeTwo)
                                thisControl.UserSelection.selectedCustomerType = 0;
                        },
                        error: function (xhr) {
                            if (xhr.status == 401) {
                                window.location = "./Api/signin";
                                return;
                            }
                            thisControl.$message.error("Error updating customer: " + xhr.responseText);
                        }
                    });
                },
            },
            computed: {
                UserSelection: function () { return this.userSelection; },
                UserDetails: function () { return this.userDetails; },
                ShouldAskForChildren: {
                    get: function () {
                        return this.UserSelection.features.some(function (f) { return f.requiresChildrensDetails === true; });
                    }
                },
                PrimaryClientGender: {
                    get: function () {
                        if (this.UserSelection.primaryClient.classification.startsWith("Male"))
                            return "Male";
                        else if (this.UserSelection.primaryClient.classification.startsWith("Female"))
                            return "Female";
                        else
                            return "";
                    },
                    set: function (value) {
                        this.UserSelection.primaryClient.classification = value + this.PrimaryClientSmokerStatus
                    }
                },
                PrimaryClientSmokerStatus: {
                    get: function () {
                        return this.UserSelection.primaryClient.classification.replace(this.PrimaryClientGender, "")
                    },
                    set: function (value) {
                        this.UserSelection.primaryClient.classification = this.PrimaryClientGender + value;
                    }
                },
                SecondaryClientGender: {
                    get: function () {
                        if (this.UserSelection.secondaryClient.classification.startsWith("Male"))
                            return "Male";
                        return "Female";
                    },
                    set: function (value) {
                        this.UserSelection.secondaryClient.classification = value + this.SecondaryClientSmokerStatus
                    }
                },
                SecondaryClientSmokerStatus: {
                    get: function () {
                        return this.UserSelection.secondaryClient.classification.replace(this.SecondaryClientGender, "")
                    },
                    set: function (value) {
                        this.UserSelection.secondaryClient.classification = this.SecondaryClientGender + value;
                    }
                },
                PolicyType: {
                    get: function () {

                        if (this.UserSelection.selectedCustomerType === null)
                            return '';

                        return this.UserSelection.secondaryClient === null && this.UserSelection.selectedCustomerType !== null ? "Single" : "Joint";
                    },
                    set: function (value) {
                        if (value == "Joint") {
                            this.UserSelection.secondaryClient = { occupation: null, classification: "Male_NonSmoker", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed', termFractureCover: false };
                        }
                        else {
                            this.UserSelection.secondaryClient = null;
                        }
                    }
                },
                DecreasingPolicy:
                {
                    get: function () {
                        return this.UserSelection.tool.toolReference === "MPPro - Pricing" || this.UserSelection.tool.toolReference === "MPCIPro - Pricing";
                    },
                    set: function (value) {
                        this.UserSelection.decreasingPolicy = value;

                        if (value === true && (this.UserSelection.tool.toolReference === "MPPro - Pricing" || this.UserSelection.tool.toolReference === "MPCIPro - Pricing"))
                            return; //already set

                        if (value === false && (this.UserSelection.tool.toolReference !== "MPPro - Pricing" && this.UserSelection.tool.toolReference !== "MPCIPro - Pricing"))
                            return; //already set

                        if (value === false) {
                            if (this.UserSelection.tool.toolReference === "MPPro - Pricing")
                                this.$emit("tool-updated", 'LifePro - Pricing');
                            else
                                this.$emit("tool-updated", 'CIPlus - Pricing');
                        }
                        if (value === true) {
                            if (this.UserSelection.tool.toolReference === "LifePro - Pricing")
                                this.$emit("tool-updated", 'MPPro - Pricing');
                            else
                                this.$emit("tool-updated", 'MPCIPro - Pricing');
                        }
                    }
                }
            },
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
